<template>
<div>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card card-outline card-primary">
                    <div class="card-header">
                        <div class="card-title">
                            <h4>Kategorien der Neuigkeiten</h4>
                        </div>
                        <nav class="py-0 bg-white navbar navbar-expand navbar-light justify-content-end">
                            <ul class="navbar-nav">  
                                <li class="nav-item mr-1">
                                    <div class="input-group input-group-sm" style="margin-top: 0;">
                                        <input type="text" class="form-control" placeholder="Suchen" @keyup.enter="loadTopics" :value="search" @input="search = $event.target.value" />
                                        <button type="submit" class="btn btn-default btn-sm mr-1" @click.prevent="loadTopics">
                                            <i class="fas fa-search"></i>
                                        </button>
                                        <button v-if="this.search != ''" type="button" class="ml-1 btn btn-danger btn-sm" @click="resetSearch">
                                            <i class="fas fa-times"></i>
                                        </button>
                                        <button class="btn btn-primary btn-sm mr-1" @click="createModal()" v-if="$auth.check('topic.create')">
                                            <i class="fas fa-plus-circle"></i> Neue Kategorie
                                        </button>             
                                    </div>
                                </li>
                                <li>
                                    <button type="button" class="mr-1 btn btn-default" @click="loadTopics">
                                        <i class="fas fa-sync"></i>
                                    </button>
                                </li>
                                <li>
                                    <button type="button" class="btn btn-primary" @click="createModal" v-if="$auth.check('topics.create')">
                                        <i class="fas fa-plus-circle"></i> Neue Kategorie
                                    </button>
                                </li>
                            </ul>
                        </nav>
                    </div>

                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th>
                                            <a href="#" @click.prevent="changeSort('id')">
                                                ID
                                                </a>
                                            <span v-if="this.params.sort_field == 'id' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                            <span v-if="this.params.sort_field == 'id' && this.params.sort_direction == 'desc'" >&darr;</span>
                                        </th>
                                        <th>
                                            <a href="#" @click.prevent="changeSort('name')">
                                                Name
                                                </a>
                                            <span v-if="this.params.sort_field == 'name' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                            <span v-if="this.params.sort_field == 'name' && this.params.sort_direction == 'desc'" >&darr;</span>
                                        </th>
                                        <th>
                                            <a href="#" @click.prevent="changeSort('slug')">
                                                Beschreibung 
                                                </a>
                                            <span v-if="this.params.sort_field == 'slug' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                            <span v-if="this.params.sort_field == 'slug' && this.params.sort_direction == 'desc'" >&darr;</span>
                                        </th>
                                        <th>Überkategorie</th>
                                        <th>Farbe</th>
                                        <th>Icon</th>
                                        <th>Aktionen</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="topic in topics.data" :key="topic.id">
                                        <td>{{topic.id}}</td>
                                        <td>{{topic.name}}</td>
                                        <td><span v-html="topic.description"></span></td>
                                        <td><span v-if="topic.parent_id != null">{{ topic.parent_id }}</span></td>
                                        <td><span class="p-2" :class="topic.color" style="border-radius: 10rem">&nbsp;&nbsp;&nbsp;&nbsp;</span></td>
                                        <td><i class="fas fa-solid" :class="topic.icon"></i></td>
                                        <td>
                                            <router-link class="mr-1 btn btn-warning btn-xs" :to="{name: 'topics.details', params: {id: topic.id}}" v-if="$auth.check('topic.edit')"><i class="fas fa-fw fa-edit"></i></router-link>
                                            <b-button size="xs" @click="deleteTopic(topic.id)" variant="danger" v-if="$auth.check('topic.destroy')"><i class="fas fa-fw fa-trash"></i></b-button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="card-footer" v-if="notEmptyObject(topics)">
                        <template v-if="topics.data.length > 0">
                            <div class="row align-items-center">
                                <div class="col-md-9">
                                    <pagination class="float-left" :data="topics" @pagination-change-page="loadTopics" :limit="3"></pagination>
                                </div>
                                <!-- <div class="col-md-2">
                                    <span class="float-right">Anzeige Eintrag {{ producers.meta.from }} - {{ producers.meta.to }} von {{ producers.meta.total }}</span>
                                </div> -->
                                <div class="col-md-1">
                                    <select class="float-right form-control form-control-sm" v-model="params.per_page">
                                        <option value="25">25 Kategorien</option>
                                        <option value="50">50 Kategorien</option>
                                        <option value="75">75 Kategorien</option>
                                        <option value="100">100 Kategorien</option>
                                        <option value="125">125 Kategorien</option>
                                        <option value="150">150 Kategorien</option>
                                    </select>
                                </div>
                            </div>                        
                        </template>
                    </div>

                        <b-modal id="topicModal" title="Neue Kategorie anlegen" ok-only ok-variant="danger" ok-title="Speichern" size="xl" @ok="handleOk">
                            <form ref="form" @submit.stop.prevent="handleSubmit" enctype="multipart/form-data">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label for="name" class="control-label">Name*</label>
                                            <input v-model="form.name" type="text" class="form-control form-cotrol-sm" :class="{'is-invalid': form.errors.has('name')}">
                                            <has-error :form="form" field="name"></has-error>
                                        </div>

                                        <div class="form-group">
                                            <label for="description" class="control-label">Beschreibung</label>
                                            <ckeditor :editor="editor" :config="editorConfig" v-model="form.description" :class="{'is-invalid': form.errors.has('description')}"></ckeditor>
                                            <has-error :form="form" field="description"></has-error>
                                        </div>

                                        <div class="form-group row">
                                            <label for="latest" class="control-label col-sm-3 col-form-label col-form-label-sm">Unter "AKTUELLES" zu finden</label>
                                            <div class="col-sm-8">
                                                <input type="checkbox" class="form-check-input" name="latest" id="latest" :class="{'is-invalid': form.errors.has('latest')}" v-model="form.latest" style="position: static; margin-left: 0;">
                                                <has-error :form="form" field="latest"></has-error>
                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <label for="color" class="control-label col-sm-3 col-form-label col-form-label-sm">Überkategorie</label>
                                            <select class="form-control form-control-sm" v-model="form.parent_id">
                                                <option v-for="topic in topics.data" :key="topic.id" :value="topic.id">{{ topic.name }}</option>
                                            </select>
                                            <has-error :form="form" field="parent_id"></has-error>
                                        </div>
                                        
                                        <div class="form-group row">
                                            <label for="color" class="control-label col-sm-3 col-form-label col-form-label-sm">Farbe*</label>
                                            <select class="form-control form-control-sm" v-model="form.color" :class="{'is-invalid': form.errors.has('color')}">
                                                <option value="bg-success">Grün</option>
                                                <option value="bg-white">Weiß</option>
                                                <option value="bg-warning">Gelb</option>
                                                <option value="bg-danger">Rot</option>
                                                <option value="bg-info">Hellblau</option>
                                                <option value="bg-navy">Dunkelblau</option>
                                                <option value="bg-primary">Blau</option>
                                                <option value="bg-secondary">Grau</option>
                                            </select>
                                            <has-error :form="form" field="color"></has-error>
                                        </div>

                                        <div class="form-group row">
                                            <label for="icon" class="control-label col-sm-3 col-form-label col-form-label-sm">Icon</label>
                                            <select class="form-control form-control-sm" v-model="form.icon">
                                                <option value="fa-newspaper">Zeitung</option>
                                                <option value="fa-lightbulb">Glühbirne</option>
                                                <option value="fa-euro-sign">Euro</option>
                                                <option value="fa-graduation-cap">Lernen</option>
                                                <option value="fa-tools">Werkzeug</option>
                                                <option value="fa-envelope-open-text">Brief</option>
                                                <option value="fa-users">Personen</option>
                                                <option value="fa-truck">Truck</option>
                                                <option value="fa-cash-register">Kasse</option>
                                                <option value="fa-bullhorn">Megafon</option>
                                                <option value="fa-laptop">Laptop</option>
                                                <option value="fa-dolly">Logistik</option>
                                            </select>
                                            <has-error :form="form" field="icon"></has-error>
                                        </div>

                                    </div>
                                </div>
                            </form>
                        </b-modal>

                </div>          
            </div>
        </div>
    </div>
</div>
</template>

<script>
import ClassicEditor from '../../../assets/packages/ckeditor5/src/ckeditor';

export default {
    name: "Kategorien",
    title: "Kategorien",
    data() {
        return {
            form: new window.Form({
                id: "",
                name: "",
                description: "",
                latest: false, 
                color: "", 
                icon: "", 
                position: "", 
                parent_id: "", 
            }),
            editor: ClassicEditor,
            editorConfig: {
                simpleUpload: {
                    uploadUrl: process.env.VUE_APP_API_URL + 'images/upload',
                    withCredentials: true,
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': 'Bearer '+ this.$auth.token()
                    }
                }
            },
            sortBy: "created_at",
            sortDesc: false,
            params: {
                sort_field: 'created_at',
                sort_direction: 'desc',
                per_page: 25,
            },
            search: '',
            page: 1,
            topics: {},
        }
    },
    watch: {
        params: {
            handler () {
                this.loadTopics();
            },
            deep: true
        },
    },
    methods: {
        onRowDoubleClick(topic){
            this.$router.push({name: 'topics.details', params: {id: topic.id}})
        },
        changeSort(field) {
            if(this.params.sort_field === field) {
                this.params.sort_direction = this.params.sort_direction == 'asc' ? 'desc' : 'asc';
    
            }
            else{
                this.params.sort_field = field;
                this.params.sort_direction = 'asc';
            }
        },
        resetSearch(){
            this.search = '';
            this.loadTopics();
        },

        handleOk(bvModalEvt) {
            bvModalEvt.preventDefault()
            this.handleSubmit()
        },

        handleSubmit() {
            this.createTopics();
        },

        createModal() {
            this.form.clear();
            this.form.reset();
            this.$bvModal.show("topicModal");
        },

        deleteTopic(id) {
            this.$swal({
                    title: "Möchtest du die Kategorie wirklich löschen?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: `Löschen`,
                    denyButtonText: `Abbrechen`,
                })
                .then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        //Send request to the Server
                        this.form
                            .delete("/topics/" + id)
                            .then(() => {
                                this.$swal({
                                    icon: "success",
                                    title: "Die Kategorie wurde erfolgreich gelöscht!",
                                    toast: true,
                                    position: 'top-end',
                                    showConfirmButton: false,
                                    timer: 2000,
                                    timerProgressBar: true,
                                    });
                                this.loadTopics();
                            })
                            .catch(() => {
                                this.$swal({
                                    icon: "error",
                                    title: "Oops...",
                                    text: "Da ist etwas schief gelaufen",
                                });
                            });
                    }
                });
        },

        createTopics() {
            this.form
                .post("/topics")
                .then(() => {
                    this.$bvModal.hide("topicModal");
                    this.$swal({
                        icon: "success",
                        title: "Die Kategorie wurde angelegt",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                    this.loadTopics();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },


        loadTopics(page) {
            if(page == undefined)
            {
                page = this.page 
            }
            this.axios
                .get("/topics", {
                    params: {
                        page: page,
                        search: this.search,
                        ...this.params
                    }
                })
                .then((response) => {
                    this.topics = response.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },
    },

    created() {
        this.loadTopics();
    },
}
</script>

<style scoped></style>
